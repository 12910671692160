export default
{
  data()
  {
    return {
      item: null,
      isLoaded: false,
      isLoading: {btnSave: false},
      toggleEditDialog: false,
      toggleDeleteDialog: false
    }
  },


  methods:
  {
    showEditDialog()
    {
      this.toggleEditDialog = true;
    },

    showDeleteDialog()
    {
      this.toggleDeleteDialog = true;
    },

    submitEdit(newItem)
    {
      return new Promise((resolve, reject) =>
      {
        this.DataEditOne(newItem)
        .then(data =>
        {
          this.toggleEditDialog = false;
          return resolve(data);
        })
        .catch(err =>
        {
          return reject(err);
        })
      });
    },

    async DataGetOne()
    {
      return new Promise(async (resolve, reject) =>
      {
        console.log("GET DATA");

        this.$http.get(this.URL_API.GET_ONE,
        {
          headers: {Authorization: "Bearer " + this.$session.get('jwt')}
        })
        .then(res =>
        {
          this.item = res.data;
          this.isLoaded = true;
          return resolve();
        })
        .catch(err =>
        {
          return reject(err);
          // TODO : Handle  error
        })

      });
    },

    DataCreateItem(newItem)
    {
      return new Promise((resolve, reject) =>
      {
        this.$http.post(this.URL_API.CREATE, newItem,
        {
          headers:
          {
            Authorization: "Bearer " + this.$session.get('jwt')
          }
        })
        .then(async res =>
        {
          try
          {
            this.isLoading.btnSave = false;

            this.$store.commit('toast/showSuccess', "Elément ajouté");

            this.$router.push({name: this.ROUTE.ONE, params: {id: res.data._id}});
          }
          catch (e)
          {
            this.isLoading.btnSave = false;
            return reject(e);
          }
        })
        .catch(err =>
        {
          this.isLoading.btnSave = false;
          return reject(err);
        })
      });
    },

    DataEditOne(newItem)
    {
      return new Promise((resolve, reject) =>
      {
        this.$http.put(this.URL_API.EDIT, newItem,
        {
          headers:
          {
            Authorization: "Bearer " + this.$session.get('jwt')
          }
        })
        .then(async res =>
        {
          try
          {
            const resItem = (res.data.hasOwnProperty("item") ? res.data.item : res.data);

            this.isLoading.btnSave = false;

            this.item = resItem;

            this.$store.commit('toast/showSuccess', "Elément modifié");

            return resolve(resItem);

          }
          catch (e)
          {
            this.isLoading.btnSave = false;
            return reject(e);
          }

        })
        .catch(err =>
        {
          this.isLoading.btnSave = false;
          return reject(err);
        })
      });
    },

    DataDeleteOne()
    {
      return new Promise((resolve, reject) =>
      {

        this.$http.delete(this.URL_API.DELETE,
        {
          headers: {Authorization: "Bearer " + this.$session.get('jwt')}
        })
        .then(async res =>
        {
          try
          {
            this.$store.commit('toast/showSuccess', "Elément supprimé");
            this.$router.push({name: this.ROUTE.ALL});

          }
          catch (e)
          {
            return reject(e);
          }

        })
        .catch(err =>
        {
          return reject(err);
        })
      });
    }
  }
}