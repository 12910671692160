import countries from '@/translations/common/countries.json'
import languages from "@/translations/common/languages.json"

export default
{
  methods:
  {
    datetimeEventsFormatter(date)
    {
      if (date)
      {
        const now = this.$moment();

        if (now.diff(date, 'years') !== 0)
          return `${this.$moment(date).format("D MMMM YYYY, HH:mm")}`;

        return `${this.$moment(date).format("D MMMM, HH:mm")}`;
      }

      return "";
    },

    datesFormatter(date1, date2)
    {
      if (date2)
        return `${this.$t('common.misc.from')} ${this.$moment(date1).format("DD/MM/YYYY")} ${this.$t('common.misc.to')} ${this.$moment(date2).format("DD/MM/YYYY")}`;
      else
        return `${this.$t('common.misc.from2')} ${this.$moment(date1).format("DD/MM/YYYY")}`;
    },

    datetimeFormatter(date)
    {
      if (date)
      {
        return this.$moment(date).format("DD/MM/YYYY HH:mm:ss");
      }

      return "";
    },

    countryFormatter(country)
    {
      return (countries.hasOwnProperty(country)) ? countries[country]['name'] : "";
    },

    langFormatter(lang)
    {
      return (languages.hasOwnProperty(lang)) ? languages[lang]['name'] : "";
    },

    channelDataFormatter(type, data)
    {
      if (type === "EMAIL")
        return data.email;
      else if (type === "PHONE")
        return data.phone;
    }
  }
}