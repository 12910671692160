<template>
  <v-container fluid>
    <v-row justify="start" class="ma-2" no-gutters>

      <v-col cols="12" class="mt-3">

        <v-row justify="space-between" align="center" no-gutters>

          <div class="display-1">{{$t('subscriptions.titles.pageTitle')}}</div>

        </v-row>
      </v-col>

      <!--v-col cols="12" class="mt-8">
        <div>
          <v-text-field
            v-model="search"
            append-icon="far fa-search"
            :label="$t('common.inputs.search')"
            clearable
            v-on:click:clear="clearInput"
            @keyup.enter.native="searchTrigger"
            outlined>
          </v-text-field>
        </div>
      </v-col-->

      <!-- Table -->
      <v-col cols="12" class="mt-6">
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="totalItems"
          :options.sync="pagination"
          :loading="loading"
          :items-per-page="20"
          v-on:click:row="goOne"
          style="min-height: 600px"
          sort-by="label"
          :sort-desc="false"
          :footer-props="{'items-per-page-options': itemsPerPage}"
          class="elevation-1">

          <!-- Label -->
          <template v-slot:item.name="{item}">
            {{item.person.firstname | truncate }}
            {{item.person.name | truncate}}
          </template>

          <!-- Email -->
          <template v-slot:item.email="{item}">
            {{item.email | truncate}}
          </template>

          <!-- Plan -->
          <template v-slot:item.plan="{item}">
            {{item.subscription.plan}}
          </template>

          <!-- State -->
          <template v-slot:item.state="{item}">

            <v-chip v-if="item.enabled" label outlined color="success" class="mr-2">
              <v-icon left>fas fa-check</v-icon>
              <div class="font-weight-medium">{{$t('common.misc.enabled')}}</div>
            </v-chip>

            <v-chip v-else label outlined color="error" class="mr-2">
              <v-icon left>fas fa-times</v-icon>
              <div class="font-weight-medium">{{$t('common.misc.disabled')}}</div>
            </v-chip>
          </template>
        </v-data-table>
      </v-col>

    </v-row>

    <!-- Add dialog --
    <SubscriptionsAddDialog
      v-if="showAddDialog"
      :value="showAddDialog"
      @submit="customSubmit"
      @close="(needRefresh) => closeAddDialog(needRefresh)" /-->

  </v-container>
</template>

<script>
  import vuetifyDatatable from '@/mixins/vuetifyDataTable';
  import dataMixin from "@/mixins/data"
  import formatter from "@/mixins/formatter";
  import utils from "@/mixins/utils";

  export default {

    name: "Subscriptions",

    components: {
      //ButtonAdd: () => import('@/components/Buttons/ButtonAdd')
    },

    mixins: [vuetifyDatatable, formatter, utils, dataMixin],

    data() {
      return {
        showAddDialog: false,

        headers:
        [
          {
            text: this.$t('subscriptions.fields.label'),
            value: "name",
            width: "20%"
          },

          {
            text: this.$t('subscriptions.fields.email'),
            value: "email",
            sortable: false
          },

          {
            text: this.$t('subscriptions.fields.plan'),
            value: "plan",
            sortable: false
          },

          {
            text: this.$t('subscriptions.fields.state'),
            value: "state",
            sortable: false
          }
        ],

        URL_API:
        {
          GET_ALL: "/users",
        },

        ROUTE:
        {
          ONE: "subscriptions/id"
        }
      }
    },

    created()
    {
      this.setHead(this.$t('subscriptions.titles.pageTitle'));
    },

    methods: {

      customSubmit(newItem) {

        this
        .DataCreateItem(newItem)
        .then(data =>
        {
          this.toggleEditDialog = false;
          this.getDataFromApi();
        })
        .catch(err =>
        {
          if (err.response.status === 400)
            return this.$store.commit('alert/showError', this.$t('subscriptions.errors')[err.response.data.type]);
          else
            return this.$store.commit("alert/showError", this.$t('common.errors.500'));
        });
      },

      openAddDialog() {
        this.showAddDialog = true;
      },

      closeAddDialog(needRefresh = false) {

        if (needRefresh)
          this.searchTrigger();

        this.showAddDialog = false;
      }
    }
  }
</script>

<style scoped>

</style>