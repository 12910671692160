export default
{
  data()
  {
    return {

      itemsPerPage: [20, -1],
      totalItems: 0,
      items: [],
      pagination: {},
      loading: true,
      headers: [],
      search : "",
      sortBy: null,
      descending: false,
    }
  },

  methods:
  {

    goOne(item)
    {
      this.$router.push({name: this.ROUTE.ONE, params: {id: item._id}});
    },

    searchTrigger()
    {
      this.pagination = {...this.pagination};
    },

    clearInput()
    {
      this.search = "";
      this.searchTrigger();
    },

    getDataFromApi() {
      this.loading = true;

      return new Promise((resolve, reject) =>
      {
        if (this.search == null)
          this.search = "";

        const url = `${this.URL_API.GET_ALL}?offset=${(this.pagination.page-1)*this.pagination.itemsPerPage}&limit=${this.pagination.itemsPerPage}&sortDesc=${this.pagination.sortDesc}&sortBy=${this.pagination.sortBy}&search=${this.search}${typeof this.filtersURL === "string" ? this.filtersURL : ""}`;

        this.$http.get(url,
        {
          headers:
          {
            Authorization: "Bearer " + this.$session.get('jwt')
          }
        })
        .then(res =>
        {
          this.loading = false;
          console.log(res.data);
          return resolve(
          {
            items: res.data.items,
            totalItems: res.data.totalItems
          });
        })
        .catch(err =>
        {
          this.loading = false;
        });
      });
    }
  },


  watch:
  {
    pagination:
    {
      handler()
      {
        this
        .getDataFromApi()
        .then(data =>
        {
          this.loading = false;
          this.items = data.items;
          this.totalItems = data.totalItems;

          this.$store.commit("data/setModule", this.module);
          this.$store.commit("data/setPagination", this.pagination);
        })
        .catch(err =>
        {
          this.$store.commit("toast/showError", this.$t('base.errors.generic'));
        })
      },
      deep: true
    }
  },



}