var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-2",attrs:{"justify":"start","no-gutters":""}},[_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"space-between","align":"center","no-gutters":""}},[_c('div',{staticClass:"display-1"},[_vm._v(_vm._s(_vm.$t('subscriptions.titles.pageTitle')))])])],1),_c('v-col',{staticClass:"mt-6",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"min-height":"600px"},attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalItems,"options":_vm.pagination,"loading":_vm.loading,"items-per-page":20,"sort-by":"label","sort-desc":false,"footer-props":{'items-per-page-options': _vm.itemsPerPage}},on:{"update:options":function($event){_vm.pagination=$event},"click:row":_vm.goOne},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("truncate")(item.person.firstname))+" "+_vm._s(_vm._f("truncate")(item.person.name))+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("truncate")(item.email))+" ")]}},{key:"item.plan",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.subscription.plan)+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [(item.enabled)?_c('v-chip',{staticClass:"mr-2",attrs:{"label":"","outlined":"","color":"success"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fas fa-check")]),_c('div',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.$t('common.misc.enabled')))])],1):_c('v-chip',{staticClass:"mr-2",attrs:{"label":"","outlined":"","color":"error"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fas fa-times")]),_c('div',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.$t('common.misc.disabled')))])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }